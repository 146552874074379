"use client";
import { useCallback, useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "@/lib/hooks";
import { saveSearchPayload, getSearchPayload, getSearchLoading, loading, getHotels} from "@/lib/features/hotel/hotelSlice";

import DateSearch from "@/components/hero/hero-1/DateSearch";
import GuestSearch from "@/components/hero/hero-1/GuestSearch";
import LocationSearch from "@/components/hero/hero-1/LocationSearch";
import { useRouter, useSearchParams } from "next/navigation";
import { DateObject } from "react-multi-date-picker";
import { getUserCurrency, getUserCurrentCountry } from "@/lib/features/home/homeSlice";
import { HomeSearchSchema } from "@/utils/validation";
import { Formik, Form, Field } from "formik";
import { usePathname } from 'next/navigation'
import { createQueryString, transformGuests } from "@/utils/helper";

interface Room {
  adults: number;
  children_ages: (number | null)[];
  no_of_infants?: number | undefined;
}

interface Location {
  name: string;
  code: string;
  type: string;
  label: string;
}
interface FormValues {
  location: Location;
  dates: string[];
  guests: Room[];
}
type MainFilterSearchBoxProps = {
  requestFrom?: string
  onSearch?: () => any
};
import { useDebounce } from "use-debounce"; 
const MainFilterSearchBox = ( { requestFrom, onSearch } : MainFilterSearchBoxProps) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const currentPage = usePathname();
  const query: any  = useSearchParams()
  const decodedQueryParams: any = query?.get("payload") ? JSON.parse(query?.get("payload")) : {};
  const qParams: any = query?.get("q") ? query?.get("q") : {};
  const userCurrency = useAppSelector(getUserCurrency);
  const searchPayload  = useAppSelector(getSearchPayload);
  const searchLoading = useAppSelector(getSearchLoading);
  const hotelLoading = useAppSelector(loading);
  const userCurrentCountry = useAppSelector(getUserCurrentCountry);
  const [debouncedQuery] = useDebounce(decodedQueryParams, 500);
  const [debouncedQQuery] = useDebounce(qParams, 500);

  const [guests, setGuests] = useState<Room[]>([
    {
      adults: 2,
      children_ages: [],
    },
  ]);
  const initialValues: FormValues = {
    location:   { name: "", code: "", type: "", label: "" },
    dates: [
      new DateObject().format("YYYY-MM-DD"),
      new DateObject().add(2, "days").format("YYYY-MM-DD"),
    ],
    guests,
  };

  const [formData, setFormData] = useState<FormValues>(initialValues);

  //Set form data for hotel list page
  useEffect(() => {
    // console.log("re", requestFrom, debouncedQQuery )
    if(requestFrom === "hotels" &&  debouncedQuery?.location?.code && searchPayload && searchPayload[debouncedQuery?.location?.code] != "") {
      // console.log("Inside useEffect",  debouncedQuery?.location?.code);
      const id = debouncedQuery?.location?.code;
      const location =  searchPayload[id]?.location;
      const guests = searchPayload[id]?.guests;
      const dates = searchPayload[id]?.dates;
      // console.log("Updated Payload=>>", searchPayload[id]);
      setFormData((_) => ({ ..._, dates, guests, location: location }));
    }
    // if(requestFrom === "home") {
     
    //   setFormData((_: any) => ({ ..._, location: {"name":"Andorra Vella","code":126387,"type":"city","label":"AD"} }));
    // }
   
  }, [requestFrom, searchPayload, JSON.stringify(debouncedQQuery)]);

 

  const onSubmit = useCallback(async(data: FormValues) => {
    const payload: any = {
      ...data,
      currency: userCurrency?.code,
      nationality: userCurrency?.nationality_code,
    };
    const queryString = createQueryString(payload);
    console.log("onSubmit", queryString, requestFrom);
    
    const isSearchPayloadSaved = await dispatch(
      saveSearchPayload(payload)
    ).unwrap();
      
    if(isSearchPayloadSaved){
      // console.log("searchRequest", isSearchPayloadSaved);
      if(requestFrom == "hotels"){
        onSearch && onSearch();
      }
      const { location, dates, guests } = payload;
      // console.log("searchRequest before sending ", location, dates, guests);

      /*dispatch(
        getHotels({
          url: "v1/hotels/first-available-batch",
          payload: {
            code: location?.code,
            type: location?.type,
            checkin: dates && dates[0],
            checkout: dates && dates[1],
            rooms: JSON.stringify(transformGuests(guests)),
            rates: "concise",
            currency: userCurrency?.code,
            nationality: userCurrentCountry,
          },
        })
      );*/
     
      router.push(`/hotels?payload=${queryString}`);
    }  
  },[requestFrom, userCurrency, userCurrentCountry]);

  return (
    <>
    {/* Form{JSON.stringify(formData)} */}
    <Formik
     enableReinitialize={true}
      initialValues={formData}
      validationSchema={HomeSearchSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, setFieldValue }) => (
        <Form>
           {/* {JSON.stringify(values)} */}
          {/* <div className="position-relative mt-30 md:mt-20 js-tabs-content"> */}
            <div className={`${currentPage}` === "/hotels" ? 'mainSearch bg-white pr-10 py-10 lg:px-20 sm:px-10 lg:pt-5 lg:pb-20 bg-light-2 rounded-4' : 'mainSearch -w-900 bg-white px-10 py-10 lg:px-20 lg:pt-5 lg:pb-20 rounded-100'}>
              <div className="button-grid items-center">
                <Field name="location">
                  {({ field }: any) => (
                    <LocationSearch
                      {...field}
                      name="location"
                      value={values?.location}
                      setFieldValue={setFieldValue}
                    />
                  )}
                </Field>
              
                <div className="searchMenu-date px-30 sm:py-10 lg:py-20 lg:px-0 js-form-dd js-calendar">
                  <div>
                    <h4 className="text-15 fw-500 ls-2 lh-16">
                      Check in - Check out
                    </h4>
                    <Field name="dates">
                      {({ field }: any) => (
                        <DateSearch
                          name="dates"
                          value={values?.dates}
                          setFieldValue={setFieldValue}
                        />
                      )}
                    </Field>
                  </div>
                </div>

                <GuestSearch
                  name="guests"
                  guests={values?.guests}
                  setFieldValue={setFieldValue}
                />

                <div className="button-item">
                  <button
                    type="submit"
                    className="mainSearch__submit button -theme-blue-color bg-theme-brown-color h-60 px-35 col-12 rounded-100  text-white"
                    // disabled={searchLoading || hotelLoading}
                  >
                    <i className="icon-search text-20 mr-10" />
                   {searchLoading ? 'Searching...': "Search"} 
                  </button>
                </div>
              </div>
            </div>
          {/* </div> */}
        </Form>
      )}
    </Formik>
    </>
  );
};

export default MainFilterSearchBox;
