import React, { useEffect, useState, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "@/lib/hooks";
import {
  getPlaces,
  places,
  getPlacesLoading,
  getProperties,
} from "@/lib/features/home/homeSlice";
import { getSearchPayload } from "@/lib/features/hotel/hotelSlice";

import { ArrowClockwise, GeoAlt, Buildings,XLg } from "react-bootstrap-icons";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import { ErrorMessage, useField, useFormikContext } from "formik"; // Import formik hook
import "react-loading-skeleton/dist/skeleton.css"; // Import skeleton CSS
import styles from "@/components/ui/Loader.module.scss";
import { usePathname, useSearchParams } from "next/navigation";

interface Location {
  name: string;
  code: string;
  type: string;
  label: string;
}
interface LocationSearchProps {
  name: string;
  value: Location;
  setFieldValue: (field: string, value: any) => void;
}

const SearchBar: React.FC<LocationSearchProps> = ({
  name,
  value,
  setFieldValue,
}) => {
  const queryParams: any = useSearchParams();
  // const decodedQueryParams = JSON.parse(queryParams?.get("payload"));

  const dispatch = useAppDispatch();
  const [, meta] = useField(name);
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedItem, setSelectedItem] = useState<any | null>(null);
  const [preOptions, setPreOptions] = useState<any[]>([]);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const locationPayload = useAppSelector(getSearchPayload);
  const placesData = useAppSelector(places);
  const loading: boolean = useAppSelector(getPlacesLoading);
  const currentPage = usePathname();
  useEffect(() => {
    if (value?.code) {
      handleOptionClick(value);
      // console.log("Inside useEffect", value);
      //Get hotel properties if the location is a city and the current page is hotels page
      if (value?.type == "city" && currentPage === "/hotels") {
        dispatch(
          getProperties({
            apiUrl: process.env.NEXT_PUBLIC_NEXTJS_HOST,
            url: "app/properties",
            payload: { q: "", city_code: value?.code, page: 1, limit: 500 },
          })
        );
      }
    }
  }, [value]);

  const debounce = (func: Function, delay: number) => {
    let timeout: NodeJS.Timeout;
    return (...args: any[]) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };

  const loadOptions = async (inputValue: string) => {
    if (!inputValue) return;
    const { payload } = await dispatch(
      // getPlaces({
      //   url: "v1/places",
      //   payload: { q: inputValue, page: 1, limit: 500 },
      // })
      getPlaces({
        apiUrl: process.env.NEXT_PUBLIC_NEXTJS_HOST,
        url: "app/search",
        payload: { query: inputValue, limit:  process.env.NEXT_PUBLIC_REDIS_SEARCH_LIMIT },
      })
      
    );
    const data = payload?.data?.data;
    if (data?.length) {
      setShowOptions(true);
      setPreOptions(data);
      setDropdownOpen(true);
    }
  };

  const debouncedLoadOptions = useCallback(
    debounce((inputValue: string) => loadOptions(inputValue), 1000),
    []
  );

  useEffect(() => {
    if (placesData?.length) {
      setPreOptions(placesData);
      console.log("searchValue", searchValue)
      if(searchValue){
        setDropdownOpen(true);
      }
      
    }
  }, [placesData]);

  const handleInputChange = useCallback((value: string) => {
    setSearchValue(value);
    if (value?.length >= 2) {
      debouncedLoadOptions(value);
    }
  }, []);

  const handleOptionClick = useCallback((item: any) => {
    // console.log("handleOptionClick", item);
    if (item?.name) {
      setSearchValue(item?.name);
      setSelectedItem(item);
      setFieldValue(name, item); // Set the formik field value
      setShowOptions(false);
      setDropdownOpen(false);
    }
  }, []);

  const handleInputClick = useCallback(() => {
    console.log("handleInputClick");
    if (preOptions?.length > 0) {
      setShowOptions(true);
    }
  }, []);

  const handleClear = useCallback(() => {
    setSearchValue("");
    setSelectedItem(null);
    setFieldValue(name, {}); // Clear the formik field value
    setShowOptions(false);
  }, []);

  // useEffect(() => {
  //   if(locationPayload && decodedQueryParams) {
  //     const { location } = decodedQueryParams;
  //     const code = location?.code;
  //     setFieldValue("location", location)
  //     console.log("Inside useEffect", locationPayload, decodedQueryParams);
  //   }
  // },[locationPayload])
  const LocationSearchSkeleton = () => (
    <>
      {Array?.from({ length: 5 }).map((_, index) => (
        <li
          className="-link d-block col-12 text-left rounded-4 px-20 py-15 js-search-option mb-1"
          key={index}
        >
          <div className="d-flex">
            <div className="icon-location-2 text-light-1 text-20 pt-4" />
            <div className="ml-10">
              <div className="text-15 lh-12 fw-500 js-search-option-target">
                <Skeleton width={150} />
              </div>
              <div className="text-14 lh-12 text-light-1 mt-5">
                <Skeleton width={100} />
              </div>
            </div>
          </div>
        </li>
      ))}
    </>
  );

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  return (
    <div className="searchMenu-loc px-30 sm:py-10 lg:py-20 lg:px-0 js-form-dd js-liverSearch">
      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} aria-labelledby="searchbar-label">
        <DropdownToggle caret={false} tag="div" className="dropdown-toggle" role="button"
    aria-expanded={dropdownOpen}>
          <h4 className="text-15 fw-500 ls-2 lh-16">Location</h4>
          <div className="text-15 text-light-1 ls-2 lh-16 d-flex align-items-center">
            <input
              autoComplete="off"
              type="search"
              placeholder="Where are you going?"
              className={`js-search js-dd-focus position-relative ${
                meta?.touched && meta?.error ? "input-bottom-error" : ""
              }`}
              value={searchValue || ""}
              onChange={(e) => handleInputChange(e.target.value)}
              onClick={handleInputClick}
              style={{ flex: 1 }}
              aria-haspopup="listbox"
              aria-expanded={showOptions}
              aria-controls="location-search-options"
              aria-label="Search location"
            />
            {searchValue && (
              <XLg
                title="Reset"
                className="clear-icon ml-2"
                onClick={handleClear}
                size={15}
                aria-label="Clear search input"
              />
            )}
            {loading && <ArrowClockwise className={styles.spinner} size={15}  aria-hidden="true" />}
            {/* <ErrorMessage name={"location.code"}  component="div" className="error-message" /> */}
            {/* {errors[name] && touched[name] && (
              <div className="text-danger">{errors[name]}</div>
            )} */}
          </div>
        </DropdownToggle>

        <DropdownMenu
          className="shadow-2 min-width-400 searchMenu-loc__search-bar-dropdown"
          end
          autoClose="outside"
          role="listbox"
        >
          <div className="bg-white px-20 py-20 sm:px-0 sm:py-0 rounded-4 scrollable-container">
            <ul className="y-gap-5 js-results">
              {!loading && preOptions?.length == 0 && (
                <li
                  className={`-link d-block col-12 text-left rounded-4 px-20 py-15 js-search-option mb-1`}
                >
                  Type or search
                </li>
              )}
              {loading ? (
                <LocationSearchSkeleton />
              ) : (
                
                preOptions?.length > 0 && preOptions?.map((item: any) => (
                  <li
                    className={`-link d-block col-12 text-left rounded-4 px-20 py-15 sm:py-10  js-search-option mb-1 ${
                      selectedItem?.code === item?.document?.code ? "active" : ""
                    } sm:text-12`}
                    key={item?.document?.code}
                    role="button"
                    onClick={() => handleOptionClick(item?.document)}
                  >
                    <div className="d-flex">
                      <div>
                        {item?.document?.type === "city" ? (
                         <GeoAlt className="text-light-1" size={20}  aria-hidden="true" />
                        ) : (
                          <Buildings className="text-light-1" size={20}  aria-hidden="true" />
                        )}
                      </div>
                      {/* {JSON.stringify(item)} */}
                      <div className="ml-10">
                        <div className="text-15 sm:text-14 lh-12" dangerouslySetInnerHTML={{ __html: item?.document?.name }}></div>
                        <div className="text-14 sm:text-12  lh-12 text-light-1 mt-5">
                        {item?.document?.label}
                        </div>
                      </div>
                    </div>
                  </li>
                ))
              )}
            </ul>
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default SearchBar;
